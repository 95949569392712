import React from 'react'
import Layout from '../components/layouts/MainLayout'

import Hero from '../components/sections/home/Hero'
import Materials from '../components/sections/global/CountertopMaterialSection'
import WhyChooseUs from '../components/sections/global/WhyChooseUs'
 
const metaData = {
  title: "Granite Countertops Greenville SC | Sales, Fabrication, Installation",
  description: "Leading fabricator and installer of granite countertops, marble countertops and quartz countertops in Greenville, SC. FREE IN-HOME ESTIMATES"
}

export default function index() {
  return (
    <Layout metaData={metaData}>

      <Hero />

      <Materials />

      <WhyChooseUs />
      
    </Layout>
  )
}

