import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const DetailsIcon = () => (
  <div className="cursor-pointer absolute bottom-0 right-0 hover:shadow-outline">
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    </div>
  </div>
)

export default function CountertopMaterialSection() {

  return (
    <section>
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-extrabold sm:text-4xl">Countertop Materials</h2>
              <p className="text-xl text-gray-500">Greenville Countertops has the largest selection of natural stone and manufactured quartz slabs in the Upstate</p>
            </div>
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">

              <li className="transform hover:scale-110 motion-reduce:transform-none">
                <Link to="/granite-countertops">
                  <div className="space-y-4">
                    <figure className="relative aspect-w-3 aspect-h-2">
                        <StaticImage
                          src="../../../images/sections/countertop-material/granite-countertop-material.jpg"
                          alt="Granite Countertop Material"
                        />
                        <DetailsIcon />
                    </figure>
                    <div className="relative space-y-2">
                      <div className="mt-5">
                        <dt className="text-lg leading-6 font-medium text-gray-900">
                          <h2>Granite Countertops</h2>
                        </dt>
                        <dd className="mt-2 text-base text-gray-500">
                          With the largest number of suppliers in the area we provide the best choice for your new granite countertops. Because we buy large volumes we can pass those savings to our customers.
                        </dd>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <div className="space-y-4">
                  <figure className="relative aspect-w-3 aspect-h-2">
                    <Link to="/quartz-countertops">
                      <StaticImage
                        src="../../../images/sections/countertop-material/quartz-countertop-material.jpg"
                        alt="Quartz Countertop Material"
                      />
                      <DetailsIcon />
                    </Link>
                  </figure>
                  <div className="space-y-2">
                    <div className="mt-5">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        <h2>Quartz Countertops</h2>
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        Our quartz countertop selection provides a wide variety of colors and patterns to choose from. We carry all major brands of quartz countertops as well as our own options at a great price.
                      </dd>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="space-y-4">
                  <figure className="relative aspect-w-3 aspect-h-2">
                    <Link to="/marble-countertops">
                      <StaticImage
                        src="../../../images/sections/countertop-material/marble-countertop-material.jpg"
                        alt="Marble Countertop Material"
                      />
                      <DetailsIcon />
                    </Link>
                  </figure>
                  <div className="space-y-2">
                    <div className="mt-5">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        <h2>Marble Countertops</h2>
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        The beauty of natural stone marble countertops can add to any decor in virtually every room of a house. The distinctive veining marble is known for makes each slab unique in appearance.
                      </dd>
                    </div>
                  </div>
                </div>
              </li>

              
            </ul>

          </div>
        </div>
      </div>

    </section>



  )
}
