import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Hero() {

  return (

    <div className="relative bg-primary-100">
      <div className="relative bg-white shadow">

          <section className="lg:relative">
            <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
              <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-5xl">
                  <span className="block xl:inline">Greenville's &nbsp;</span>
                  <span className="block text-primary-600">Countertop Fabricator</span>
                </h1>
                <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                  Find your perfect countertop material from our selection of quartz, marble and granite countertops. Sourced from the highest quality vendors, our stone slab collection is unmatched in the Greenville - Spartanburg area.
                </p>
                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Link to="/contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10">
                      Get started
                    </Link>
                  </div>
                  <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <Link to="/contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                      Questions?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">

              <StaticImage
                src="../../../images/home/black-pearl-granite-countertop-kitchen-island.jpg"
                alt="Black Pearl granite countertop and kitchen island" 
              />

            </div>
          </section>
  

      </div>
    </div>


  )
}
